

.table-container {
    border-bottom: solid 1px #ddd;
    width: 100%;
} 
.records{
    width: 100%; 
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
}

.navigation-container {display: flex; flex-direction: row; justify-content: flex-end;}

.navigation {padding: 10px; cursor: pointer;}

.table-search-box { 
    width: 20%; padding: 5px; border: solid 1px #ccc; border-radius: 5px;
}

.table-search-box:focus {
    outline: none;
}
.copy-link-container {
  position: relative;
  display: inline-block;
}

.copy-link-text {
  padding: 5px 10px;
  background-color: #f1f1f1;
  color: #333;
  border-radius: 5px;
  cursor: pointer;
}

.copy-link-text:hover {
  background-color: #ddd;
}

.copy-link-tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  /* white-space: nowrap; */
}

.copy-link-tooltip::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}
